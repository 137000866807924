<template>
  <div class="product-container">
    <div class="product-img-container">
      <img
        src="@/assets/images/product_img.jpg"
        alt="product-img"
      />
    </div>
    <div class="product-content">
      <h3 class="product-name">
        <a href="#">{{ productDetail.title }}</a>
      </h3>
      <p
        class="produst-status"
        :class="productDetail.status == 'available' ? 'available' : 'not-available'"
      >
        {{
          productDetail.status == 'available'
          ? $t('Product.AVAILABLE')
          : $t('Product.NOT_AVAILABLE')
        }}
      </p>
      <div class="prd-price-container">
        <p class="produst-price">
          € {{ productDetail.price }}
        </p>
        <button
          v-if="productDetail.status == 'available'"
          class="add-cart-btn button-element"
          @click="addToCart"
        >
          <i class="fas fa-cart-plus"></i> {{ $t('Product.ADD_TO_CART') }}
        </button>
      </div>

      <product-quantity-picker
        :quantity="product.quantity"
        v-if="productDetail.status == 'available'"
        @change="updateQuantity"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  const ProductQuantityPicker = () => import('@/components/product/ProductQuantityPicker')

  export default {
    name: 'Product',

    components: {
      ProductQuantityPicker
    },

    props: {
      productDetail: {
        type: Object,
        default: () => {}
      }
    },

    data: () => {
      return {
        product: {},
        isProductExistInCart: false
      }
    },

    computed: {
      ...mapGetters({ cartItems: 'getCartItems' })
    },

    created () {
      this.checkProductOnCart()
    },

    methods: {
      ...mapActions([
        'addItemToCart',
        'removeItemFromCart'
      ]),

      addToCart () {
        if (this.product.quantity > 0) {
          this.addItemToCart(this.product)

          if (!this.isProductExistInCart) {
            this.$toast.open ({
              message: this.$i18n.t('Product.TOAST_MESSAGE.SUCCESS_ADD_TO_CART'),
              type: 'success'
            })
          }

          this.isProductExistInCart = true
        } else {
          this.removeItemFromCart(this.product)
          this.isProductExistInCart = false
        }
      },

      updateQuantity (q) {
        this.product.quantity = q

        if (this.isProductExistInCart) {
          this.addToCart()
        }
      },

      checkProductOnCart () {
        let quantity = 1
        const existCartItem = this.hasProductInCart(this.productDetail.id)

        if (existCartItem) {
          quantity = existCartItem.quantity
          this.isProductExistInCart = true
        }

        this.product = Object.assign({}, { ...this.productDetail, quantity })
      },

      hasProductInCart (productId) {
        const item = this.cartItems.find((item) => { return item.id === productId })
        return typeof item !== 'undefined' ? item : false
      },
    }
  }
</script>
